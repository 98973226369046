import axios from 'axios';

// Use environment variable for the base URL
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

// Function to fetch tasks by user
export const fetchTasks = async (userId: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/tasks/${userId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching tasks:", error);
        throw error;
    }
};

// Function to update task state (e.g., approve task)
export const updateTaskState = async (taskId: string, newState: string) => {
    try {
        const response = await axios.patch(`${API_BASE_URL}/tasks/${taskId}`, {
            state: newState,
        });
        return response.data;
    } catch (error) {
        console.error("Error updating task state:", error);
        throw error;
    }
};

// Add other API calls as needed (e.g., create task, delete task, etc.)
