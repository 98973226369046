import { Button } from "../ui/button";
import { Home, ListTodo, User, LogOut } from 'lucide-react';

export default function SidebarContent() {
  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-center h-16 border-b">
        <img src="/zephy_logo.svg" className="px-10 object-contain" alt="Zephy Logo" />
      </div>
      <nav className="flex-1 overflow-y-auto">
        <ul className="p-2 space-y-1">
          <li>
            <Button variant="ghost" className="w-full justify-start">
              <Home className="mr-2 h-4 w-4" />
              Dashboard
            </Button>
          </li>
          <li>
            <Button variant="ghost" className="w-full justify-start">
              <ListTodo className="mr-2 h-4 w-4" />
              Tasks
            </Button>
          </li>
          <li>
            <Button variant="ghost" className="w-full justify-start">
              <User className="mr-2 h-4 w-4" />
              Profile
            </Button>
          </li>
        </ul>
      </nav>
      <div className="p-4 border-t">
        <Button variant="outline" className="w-full">
          <LogOut className="mr-2 h-4 w-4" />
          Log out
        </Button>
      </div>
    </div>
  );
}
