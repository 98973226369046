import React, { useEffect, useState } from 'react';
import { fetchTasks, updateTaskState } from '../services/api';

const Tasks: React.FC = () => {
    const [tasks, setTasks] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const userId = 'example-user-id'; // Replace with actual user ID

    useEffect(() => {
        const loadTasks = async () => {
            try {
                const data = await fetchTasks(userId);
                setTasks(data);
            } catch (error) {
                console.error("Failed to load tasks:", error);
            } finally {
                setLoading(false);
            }
        };

        loadTasks();
    }, [userId]);

    const handleTaskApproval = async (taskId: string) => {
        try {
            const updatedTask = await updateTaskState(taskId, 'approved');
            setTasks(tasks.map(task => task.id === taskId ? updatedTask : task));
        } catch (error) {
            console.error("Failed to approve task:", error);
        }
    };

    if (loading) {
        return <div>Loading tasks...</div>;
    }

    return (
        <div>
            <h1>Tasks</h1>
            <ul>
                {tasks.map(task => (
                    <li key={task.id}>
                        <h2>{task.title}</h2>
                        <p>{task.description}</p>
                        <button onClick={() => handleTaskApproval(task.id)}>Approve</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Tasks;
